@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
body {
  margin: 0;
  box-sizing: border-box;
  background-color: radial-gradient(
    48.35% 153.13% at 49.62% 49.95%,
    #ffcf35 0%,
    #b58e13 100%
  );
}
